import { ThunkDispatch } from '@reduxjs/toolkit';
import {
  fetchEC2Servers,
  fetchModulesCandidates,
} from 'api/manageModules/manageModulesThunks';
import { useAppSelector } from 'redux/hooks';
import { addToast, selectNotifications } from 'redux/toast/toastSlice';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

export default function useDeployModuleModal(
  name: string,
  version: string,
  dispatch: ThunkDispatch<any, any, any>,
  setEnableIndex: Dispatch<SetStateAction<number>>,
  setModules: Dispatch<SetStateAction<IDeployModuleSearchRow[] | undefined>>,
  setServers: Dispatch<SetStateAction<IDeployModuleServerRow[] | undefined>>
) {
  const [loading, setLoading] = useState(false);
  const toasters = useAppSelector(selectNotifications);

  const searchModule = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      if (name !== '') {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchModulesCandidates({
              artifactId: name.endsWith('*') ? name : `${name}*`,
              version: version !== '' ? version : '*',
            })
          ).unwrap();
          if (response.length > 0) {
            setModules(
              response.map((module: IDeployModuleSearchRow) => ({
                ...module,
                selectionMethod: () => {
                  setEnableIndex(2);
                },
              }))
            );
            setEnableIndex(1);
            const serversResponse = await dispatch(fetchEC2Servers()).unwrap();
            setServers(serversResponse);
          } else {
            if (
              toasters?.length === 0 ||
              toasters?.findIndex((t: any) => t.id.includes('no-modules')) ===
                -1
            ) {
              dispatch(
                addToast({
                  id: `no-modules-${(Math.random() + 1).toString()}`,
                  type: 'informationToast',
                  status: 'warning',
                  additionalData: {
                    text: 'No modules match your search criteria',
                  },
                })
              );
            }
          }
        } catch (error) {
          dispatch(
            addToast({
              id: `no-modules-${(Math.random() + 1).toString()}`,
              type: 'informationToast',
              status: 'error',
              additionalData: {
                text:
                  consoleErrorMessage(error) ??
                  'There was an error while retrieving the modules, try again',
              },
            })
          );
        }
        setLoading(false);
      }
    },
    [name, version]
  );

  return {
    searchModule,
    loading,
  };
}
