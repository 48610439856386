import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  combineModuleVersions,
  transformModuleServersResponse,
} from 'dto/modules/deployModules';
import {
  IAvailableModuleServer,
  IDeployModuleSearchResponse,
  IDeployModuleServersResponse,
} from 'interfaces/modules/module.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';
const BASE_URL = '/csb/modules';

export const fetchAvailableModules = createAsyncThunk(
  'manageModules/fetchAvailableModules',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IAvailableModuleServer>(
        `${BASE_URL}/available`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export const fetchAvailableModulesWithoutLoading = createAsyncThunk(
  'manageModules/fetchAvailableModulesWithoutLoading',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IAvailableModuleServer>(
        `${BASE_URL}/available`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export interface IfetchModulesCandidates {
  artifactId: string;
  version?: string;
}
export const fetchModulesCandidates = createAsyncThunk(
  'manageModules/fetchModulesCandidates',
  async (props: IfetchModulesCandidates, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDeployModuleSearchResponse[]>(
        `${BASE_URL}/candidates/${props.artifactId}?version=${props.version}`,
        {
          withCredentials: true,
        }
      );
      return combineModuleVersions(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);

export const fetchEC2Servers = createAsyncThunk(
  'manageModules/fetchEC2Servers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDeployModuleServersResponse>(
        `${BASE_URL}/available/servers`,
        {
          withCredentials: true,
        }
      );
      return transformModuleServersResponse(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        consoleErrorMessage(error);
        return rejectWithValue(error.response?.data || error.message);
      }
      consoleErrorMessage(UNEXPECTED_ERROR_MESSAGE);
      return rejectWithValue(UNEXPECTED_ERROR_MESSAGE);
    }
  }
);
