import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import styles from './DeployModuleModal.module.scss';
import { useState } from 'react';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';
import SelectServersSection from './components/SelectServersSection/SelectServersSection';
import SelectModuleSection from './components/SelectModuleSection/SelectModuleSection';
import SearchResultsSection from './components/SearchResultsSection/SearchResultsSection';
import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';

interface IDeployModuleModal {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

export default function DeployModuleModal({
  register,
  watch,
}: IDeployModuleModal) {
  const [enableIndex, setEnableIndex] = useState(0);
  const [modules, setModules] = useState<IDeployModuleSearchRow[] | undefined>(
    undefined
  );
  const [servers, setServers] = useState<IDeployModuleServerRow[] | undefined>(
    undefined
  );

  return (
    <CsbErrorBoundary>
      <div
        className={`${styles['deploy-module-modal-container']}`}
        data-testid="DeployModuleModal"
      >
        <SelectModuleSection
          register={register}
          watch={watch}
          setEnableIndex={setEnableIndex}
          setServers={setServers}
          setModules={setModules}
        />
        <div className={enableIndex < 1 ? styles['disabled-section'] : ''}>
          <SearchResultsSection modules={modules} />
        </div>
        <div className={enableIndex < 2 ? styles['disabled-section'] : ''}>
          <SelectServersSection servers={servers} />
        </div>
      </div>
    </CsbErrorBoundary>
  );
}
