import {
  IErrorLogFromServer,
  IErrorLogRow,
} from 'interfaces/error-log-row.interface';
import { IStepInformation } from 'interfaces/orchestration.interface';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const objectFromErrorLogData = (
  log: IErrorLogFromServer[],
  steps?: IStepInformation[]
): IErrorLogRow[] => {
  return log.map((item: IErrorLogFromServer) => {
    return {
      createdTime: DateUtils.formatDateFromUtcString(item.timestamp) ?? '-',
      severity: item.logLevel ?? 'INFO',
      ['Step name']: item.metadata?.CSBWorkflowStepName
        ? `${getStepIndex(steps, item.metadata.CSBJobStepId)} ${
            item.metadata?.CSBWorkflowStepName
          }`
        : 'No Step name available',
      message: item.message ?? '-',
    } as IErrorLogRow;
  });
};

const getStepIndex = (
  steps?: IStepInformation[],
  jobStepId?: string
): string => {
  const firstStepIndex = (steps?.[0]?.workflowStepId ?? 0) + 1;
  const currentStep = steps?.find(
    (step) => step.jobStepId === Number(jobStepId)
  );
  if (currentStep) {
    const currentStepIndex = currentStep.workflowStepId;
    const stepDifference = firstStepIndex - currentStepIndex;
    const formattedStepDifference =
      stepDifference < 10 ? `0${stepDifference}` : stepDifference.toString();
    return formattedStepDifference;
  }
  return '';
};
